export let texts = [
  {
      "id": "bugs",
      "name":"Chrome Bugs",
      "title": "Chrome Bugs",
      "body": `
          Some versions of the Chrome browser set the minimum font-size to 6px. This causes issues when zooming the prototypes. To fix
          this issue, you can change the settings as follows:
          <br>
            <ol>
            <li>
                Open the Preferences (Chrome Menu > Settings)
            </li>
            <li>
                Search for "Customize fonts"
            </li>
            <li>
                Open the settings page
            </li>
            <li>
                Set the "minimum font size" slider to 0
            </li>
        </ol>
      `,
      "paragraphs": [
      ]
  }
]