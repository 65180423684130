export let texts = [
    {
        "id": "Contact",
        "name":"Contact",
        "title": "Contact",
        "body": `
            We are always looking for feedback and ideas for improvements.
        `,
        "hasContact": true
    }
]